<template>
    <v-carousel
            cycle
            :height="$vuetify.breakpoint.smAndUp ? '150' : '150'"
            hide-delimiter-background
            hide-delimiters
            :show-arrows="false"
            :class="$vuetify.breakpoint.smAndUp ? 'ml-10' : ''"
    >
        <v-carousel-item
                v-for="(rating, i) in AppStore.data.unit.ratings"
                :key="i"
        >
<!--            <v-sheet-->
<!--                    color="white"-->
<!--                    height="100%"-->
<!--            >-->
                <v-container fluid class="py-5">
                    <v-row no-gutters style="flex-wrap: nowrap;">
                        <div style="min-width: 100px; max-width: 100%;" class="flex-grow-0 flex-shrink-1">
                            <div class="d-flex flex-column">
                                <div class="d-flex flex-row">
<!--                                    <v-rating v-model="rating.rate">-->
<!--                                        <template v-slot:item="props" class="mr-2 carrousel-stars">>-->
<!--                                            <v-icon-->
<!--                                                style="font-size: 100px;"-->
<!--                                                :color="getRateColor(props.isFilled)"-->
<!--                                                small-->
<!--                                                class="pa-0"-->
<!--                                                @click="props.click">-->
<!--                                                {{ props.isFilled ? 'mdi-star' : 'mdi-star-outline' }}-->
<!--                                            </v-icon>-->
<!--                                        </template>-->
<!--                                    </v-rating>-->

                                    <v-rating
                                            half-increments
                                            dense
                                            readonly
                                            size="25"
                                            color="orange"
                                            background-color="grey"
                                            v-model="rating.rate"
                                            icon-label=""
                                            class="mr-2 carrousel-stars"></v-rating>

                                    <span class="font-weight-bold" style="margin-top: 3px;">{{
											rating.rate + '/5'
                                        }}</span>
                                </div>
                            </div>
                            <div class="d-flex flex-column ml-1 mt-1">
                                    <span class="mr-auto font-weight-medium caption grey--text text-body-1 text--darken-1"
                                          v-if="rating.source === 'google' || rating.source === 'google_places'">
                                              {{ $t('views.timeline.rating_from') }} {{ rating.consumer.identifier }}
                                               <span v-if="rating.consumer.influence" class="caption">
                                               {{ $t('views.home.local_guide') }} {{ rating.consumer.influence / 10 }}
                                               </span> {{ $t('views.timeline.of') }}     <span class="d-inline-block grey--text text--darken-1"
                                                                    style="margin-top: 2px">
                                        {{ Helpers.parseDate(rating.posted_at_tz, 'DD/MM/YYYY') }}
                                        </span>
                                          </span>

                                <span class="font-weight-medium caption grey--text text-body-1 text--darken-1"
                                      v-else-if="rating.source === 'civiliz' && rating.consumer && rating.consumer.signature !== 'anonymous' && rating.consumer.signature !== 'anonyme'">
                                            {{ $t('views.timeline.rating_from') }} {{ rating.consumer.signature }} {{ $t('views.timeline.of') }}     <span
                                        class="d-inline-block grey--text text--darken-1"
                                        style="margin-top: 2px">
                                        {{ Helpers.parseDate(rating.posted_at_tz, 'DD/MM/YYYY') }}
                                        </span>
                                          </span>
                                <span v-else class="font-weight-medium text-body-1 grey--text text--darken-1">
                                         {{ $t('views.timeline.rating_of') }} <span
                                        class="d-inline-block grey--text text--darken-1"
                                        style="margin-top: 2px">
                                        {{ Helpers.parseDate(rating.posted_at_tz, 'DD/MM/YYYY') }}
                                        </span>
                                    </span>
                            </div>
                            <div class="mt-3 block-ellipsis ml-1 text-body-2" v-if="rating.contents.length">
                                {{ rating.contents[0].content }}
                            </div>
                        </div>
                    </v-row>
                </v-container>
<!--            </v-sheet>-->
        </v-carousel-item>
    </v-carousel>
</template>

<script>
import AppStore from "../../stores/AppStore";
import Helpers from "../../services/Helpers";

export default {
    name: 'ratings-carousel',

    components: {},

    props: {},

    data: () => ({
        AppStore,
        Helpers,
    }),

    created() {

    },

    mounted() {

    },

    computed: {},

    watch: {},

    methods: {}
};
</script>
